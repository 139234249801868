/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

window.testLocation = function() {

  var $ = jQuery;


};

(function($) {
  var $window = $(window);
  var $body = $('body');
  var locatonPageAttempts = 10;

  $.fn.scrollbarWidth = function() {
    var outer = this.outerWidth();
    var $tmp = $('<div>').appendTo(this);
    var inner = $tmp.outerWidth();
    $tmp.remove();
    return outer - inner;
  };

  function scroll_past_menu() {
    var headerHeight = $('header.main').outerHeight();
    var menuHeight = parseInt($('main.main').css('padding-top'));
    var diff = menuHeight - headerHeight;

    // we want to start scrolled pass the circle menu
    setTimeout(function() {
      $body.scrollTop(diff);
    },0);
  }

  function init_archive() {
    scroll_past_menu();

    $('article .more-toggler').on('click', function(e) {
      e.preventDefault();
      var $a = $(this).closest('article').find('.more-link');
      $a.closest('.entry-content').find('.content').slideToggle(300);
      $a.toggleClass('closed');
    });
  }

  function min_height_fix() {
    $('#app, #page-404').each(function() {
      var h = $(this).outerHeight();
      $(this).css('min-height', h + $(window).height() - $('body').height());
    });
  }

  // make the store location phone number show up in the header
  function show_location_page_phone_number() {
    var phone = $('.location-main .phone');
    if (phone.length) {
      $('#phone_number_displayer span').text(phone.text());
    }
  }

  function show_location_page_deals() {
    var locationDeals = $('#location-deals');
    if (locationDeals.length) {
      var storeId = locationDeals.data('storeId');
      var url = locationDeals.data('apiUrl');
      var token = window.localStorage.getItem('panago.token');

      if (token) {
        token = JSON.parse(token);
        token = token.token;
      }

      if (!token) {
        locatonPageAttempts--;
        if (locatonPageAttempts >= 0) {
          setTimeout(show_location_page_deals, 300);
        }
      } else {
        $.ajax({
          method: 'POST',
          url: url,
          contentType: 'application/json',
          dataType: 'json',
          beforeSend: function(request) {
            request.setRequestHeader("Accept", "application/vnd.panago_api.v1");
            request.setRequestHeader("Authorization", 'Token token="'+token+'"');
          },
          data: JSON.stringify({
            store_number: storeId
          }),
          processData: false
        }).done(function(payload) {
          if (payload && payload.data && payload.data.products) {
            var productIds = payload.data.products.map(function(product) {
              return product.id;
            });
            locationDeals.find('.feature').each(function() {
              var productId = $(this).data('productId');
              if (productIds.indexOf(productId) >= 0) {
                $(this).show();
              } else {
                $(this).remove();
              }
            });
          }
        });
      }
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        show_location_page_deals();
        show_location_page_phone_number();

        $('.feature-slides.multiple').each(function() {
          var $this = $(this);

          // we ported these options over from unslider (to slick). Becuase some
          // options come from CMS, (data-X attrs), we translate some options
          // to slick supported options.
          var options = {
            infinite: true,
            autoplay: true,
            speed: 500,
            delay: 5000,
            arrows: false,
            adaptiveHeight: true,
            animation: '',
            dots: true,
          };

          for (var i in options) {
            var attr = $this.attr('data-'+i);
            if (attr) {
              if (i === 'infinite' || i === 'autoplay' || i === 'arrows') {
                attr = attr==="1";
              }

              if (i === 'speed' || i === 'delay') {
                attr = parseInt(attr);
              }

              options[i] = attr;
            }
          }

          options.autoplaySpeed = options.delay;

          if (options.animation === 'fade') {
            options.fade = true;
          }
          // options.autoplay = false;
          delete options.animation;
          delete options.delay;

          var $slick = $this.find('.slider-component ul').slick(options);

          if (options.autoplay) {
            $slick.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
              var slide = $slick.find('li').not('.slick-cloned').eq(nextSlide);
              var delay = parseInt(slide.attr('data-duration'));
              if (!delay) {
                delay = options.autoplaySpeed;
              }
              // console.log('change',nextSlide,delay);
              $slick.slick('setOption', 'autoplaySpeed', delay);
            });
          }

          // this hack is to make sure the pricers are synced in the clones in case the cloning occurs before the pricers invoke.
          if (options.infinite && !options.fade) {
            setTimeout(function(){
              $slick.find('.slick-slide').not('.slick-cloned').each(function() {
                var item = $(this).find('.feature-item');
                if (item.length) {
                  var c = item.attr('class').match(/feature-item-\d+/)[0];
                  var pricer = $(this).find('[data-react-mount-component]');
                  if (pricer && pricer.length) {
                    var html = pricer.html();
                    $slick.find('.slick-cloned .'+c+' [data-react-mount-component]').html(html);
                  }
                }
              });

            }, options.autoplaySpeed);
          }

          $this.removeClass('loading');
        });

        if ($('.standard-page .hero h2').length) {
          scroll_past_menu();


          // In order to make the callout sticky to the top and transform it
          // for the slideout panel and for when the circle-menu slides down,
          // we need to move the callout (clone) to something outside of <main>
          var $main = $('main.main');
          var $h2 = $('.standard-page .hero h2');
          var scrollWidth = $main.scrollbarWidth();
          var headerHeight = $('header.main').outerHeight();
          var menuHeight = parseInt($('main.main').css('padding-top'));
          var h2Top = $h2.offset().top + $main.scrollTop();
          var headerBoundary = h2Top - headerHeight;
          var menuBoundary   = h2Top - menuHeight;

          var $fixedH2 = $('<div>').addClass('page-callout-fixed-h2').append($h2.clone()).appendTo($body);

          if (scrollWidth) {
            $fixedH2.css('width', "calc( 100% - "+scrollWidth+"px )");
          }

          $window.scroll(function() {
            var boundary = $body.hasClass('headroom--pinned') ? menuBoundary : headerBoundary;
            $body.toggleClass('page-callout-fixed', $window.scrollTop() > boundary);
          });
        }

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
          $body.addClass('browsing-ios');
        }

        $body.headroom({
          // scroller: $('body >main')[0],
          offset: 0,
          tolerance: 10
          // tolerance: {
          //   up: 0,
          //   down: 0
          // }
        });

        $body.on('click', 'header.main nav .right-menu-toggle a, .sidebar_close, #sidebar-overlay', function(event) {
          event.stopPropagation();
          event.preventDefault();
          $body.toggleClass('reveal-sidebar');
        });

        $('header.main nav .menu-item-has-children >a').on('click', function(e) {
          e.stopPropagation();
          e.preventDefault();
          $(this).toggleClass('sub-menu-active');
        });


        $('#comment-bubble').on('click', function() {
          if (window.olark) {
            if ($('#olark-container').hasClass('olark-hidden')) {
              olark('api.box.expand');
            } else {
              olark('api.box.shrink');
            }
          }
        });


        $('.standard-page .entry-content .read-more-content').on('click', function(e) {
          e.preventDefault();
          var $this = $(this);
          var label = $this.attr('data-label');
          var closeLabel = $this.attr('data-close-label');
          $this.toggleClass('opened');
          $this.find('a.toggler').html($this.hasClass('opened') ? closeLabel : label);
          $this.find('.inner-content').slideToggle(250);
        });

        function handlePrettySelect() {
          var sel = $(this).find(":selected");
          var label = sel.text();

          if (label === '' || label.indexOf('---') >= 0) {
            label = $(this).closest('.pretty-select').attr('data-default-label');
          }

          // label = label.replace(/\-/g,'').replace(/\*/g, '');

          $(this).closest(".pretty-select").find("label").html($.trim(label));
        }

        $(".pretty-select.wp-handled select").on("change", handlePrettySelect);

        // file input styles
        $(".wpcf7-form .input-item.file span").attr("filename", "No file selected");
        $(".wpcf7-form .input-item.file input").on("change", function() {
          var filename = $(this).val();
          var parts = filename.split("\\");
          filename = parts[parts.length-1];
          $(this).closest('span').attr("filename", filename);
        });

        $('section.image-info .toggles').on('click', 'a', function(e) {
          e.preventDefault();
          var ref = $(this).attr('ref');
          var container = $(this).closest('.image-info');
          container.find('.active').removeClass('active');
          container.find('.'+ref).addClass('active');
          $(this).addClass('active');
        });

      },
      // finalize: function() {
      //   // JavaScript to be fired on all pages, after page specific JS is fired
      // }
    },

    'blog': {
      init: init_archive
    },

    'search': {
      init: init_archive
    },

    'archive': {
      init: init_archive
    },

    'error404': {
      init: min_height_fix
    },

    'wp_react_route': {
      init: min_height_fix
  	},
  	'page_template_template_grid_layout': {
		init: function() {
			$('.premium-flip-box-full-link').click(function(e){
				e.preventDefault();
				var target = "#"+$(this).data('modal-id');
				$('main.main').toggleClass('hide-content');
				$(target).fadeIn().find('.carousel-slick').slick({
				  infinite: false,
				  slidesToShow: 3,
				  slidesToScroll: 3,
				  dots: true,
				  arrows: false,
				  responsive: [
				    {
				      breakpoint: 768,
				      settings: {
						  slidesToShow: 1,
						  slidesToScroll: 1,
				      }
				    }
				  ]
				});


			});
			$('.js-close-modal').click(function(){
				$(this).parent().fadeOut();
				$('main.main').removeClass('hide-content');

			});
			// $('.carousel-slick').slick({
			//   infinite: false,
			//   slidesToShow: 3,
			//   slidesToScroll: 3,
			//   dots: true,
			//   arrows: false
			// });
		}
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
